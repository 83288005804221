<script>
  import {
    GridContainer,
    GridColumn,
    Text,
    SubHeader,
    Header,
    Separator,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import css from './_styles'

  const cN = classNames.bind(css)

  export let withTopMargin = true
  export let withBottomMargin = true
  export let titleTag
  export let title
  export let subTitle
  export let description
  export let priceTemplate
  export let list

  const unitMiddleMaxWidth = 4

  function getUnitMiddleWidth() {
    if (list === undefined) {
      return 0
    }

    return Math.min(12 / list.length, unitMiddleMaxWidth)
  }
  function getGapWidth() {
    if (list === undefined) {
      return 0
    }

    const unitMiddleWidth = getUnitMiddleWidth()

    return (12 - list.length * unitMiddleWidth) / 2
  }
</script>

<WidgetHeader {titleTag} {title} {subTitle} withMargin={withTopMargin} class={cN('prices')} />

<GridContainer class={cN('prices')}>
  {#if list !== undefined}
    <GridColumn width={0} widthMiddle={getGapWidth()} />

    {#each list as { caption, price }, idx}
      <GridColumn
        widthMiddle={getUnitMiddleWidth()}
        class={cN('prices__item', { 'prices__item-last': idx === list.length - 1 })}>
        <Separator
          key={15}
          size={Separator.SIZES.SMALL}
          direction={Separator.DIRECTIONS.LEFT_RIGHT} />
        <div class={cN('prices__item-column')}>
          <Separator key={15} size={Separator.SIZES.SMALL} />
          <SubHeader size={SubHeader.SIZES.STRONG} class={cN('prices__item-title')}>
            {caption}
          </SubHeader>
          <Separator key={5} size={Separator.SIZES.SMALL} />
          <Header>{priceTemplate.text.replace(priceTemplate.varName, price)}</Header>
          <Separator key={15} size={Separator.SIZES.SMALL} />
        </div>
        <Separator
          key={15}
          size={Separator.SIZES.SMALL}
          direction={Separator.DIRECTIONS.LEFT_RIGHT} />
      </GridColumn>
    {/each}

    <GridColumn width={0} widthMiddle={getGapWidth()} />
  {/if}
  {#if description !== undefined}
    <GridColumn class={cN('prices__text-column', 'prices__description')}>
      {#if list !== undefined}
        <Separator key={10} size={Separator.SIZES.LARGE} />
      {/if}
      <Text>{description}</Text>
    </GridColumn>
  {/if}

</GridContainer>

<WidgetFooter withMargin={withBottomMargin} />
